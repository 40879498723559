import React from "react";

const PDFViewer = () => {
 return (
 <div>
 <iframe title="2024 Extra Add Ons" src="/assets/documents/2024AddOns.pdf" width="100%" height="1066px"/>
 </div>
 );
};

export default PDFViewer;